<template>
  <div>
    <action-header
      :is-loading="!documentTemplate"
      :actions-model="headerModel"
      :page="page"
    />

    <div class="q-pa-md">
      <form-builder :schema="schema" />

      <!-- <editor
        v-if="documentTemplate && (!documentTemplate.documentType || documentTemplate.documentType === 'text/html')"
        :model-value="documentTemplate.template"
        @update:model-value="handleUpdate"
      />
      -->
      <q-editor
        v-if="
          documentTemplate &&
          (!documentTemplate.documentType ||
            documentTemplate.documentType === 'text/html')
        "
        :model-value="documentTemplate.template"
        @update:model-value="handleUpdate"
        :dense="$q.screen.lt.md"
        :toolbar="[
          [
            {
              label: $q.lang.editor.align,
              icon: $q.iconSet.editor.align,
              fixedLabel: true,
              list: 'only-icons',
              options: ['left', 'center', 'right', 'justify'],
            },
            {
              label: $q.lang.editor.align,
              icon: $q.iconSet.editor.align,
              fixedLabel: true,
              options: ['left', 'center', 'right', 'justify'],
            },
          ],
          ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
          ['token', 'hr', 'link', 'custom_btn'],
          ['print', 'fullscreen'],
          [
            {
              label: $q.lang.editor.formatting,
              icon: $q.iconSet.editor.formatting,
              list: 'no-icons',
              options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code'],
            },
            {
              label: $q.lang.editor.fontSize,
              icon: $q.iconSet.editor.fontSize,
              fixedLabel: true,
              fixedIcon: true,
              list: 'no-icons',
              options: [
                'size-1',
                'size-2',
                'size-3',
                'size-4',
                'size-5',
                'size-6',
                'size-7',
              ],
            },
            {
              label: $q.lang.editor.defaultFont,
              icon: $q.iconSet.editor.font,
              fixedIcon: true,
              list: 'no-icons',
              options: [
                'default_font',
                'arial',
                'arial_black',
                'comic_sans',
                'courier_new',
                'impact',
                'lucida_grande',
                'times_new_roman',
                'verdana',
              ],
            },
            'removeFormat',
          ],
          ['quote', 'unordered', 'ordered', 'outdent', 'indent'],

          ['undo', 'redo'],
          ['viewsource'],
        ]"
        :fonts="{
          arial: 'Arial',
          arial_black: 'Arial Black',
          comic_sans: 'Comic Sans MS',
          courier_new: 'Courier New',
          impact: 'Impact',
          lucida_grande: 'Lucida Grande',
          times_new_roman: 'Times New Roman',
          verdana: 'Verdana',
        }"
      />
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      :disabled="!!$route.params.id"
      @back="handleDiscard"
      @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'
// import Editor from '../../components/editor/Editor.vue'

export default {
  name: 'Document',
  components: {
    ActionHeader,
    // Editor
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      types: [
        { id: 'private', name: 'Private' },
        { id: 'public', name: 'Public' }
      ],
      entityClasses: [
        { id: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance', name: 'Purchase orders' },
        { id: 'Orderadmin\\Reports\\Entity\\Report', name: 'Reports' },
        { id: 'Orderadmin\\Products\\Entity\\Order', name: 'Orders' }
      ],
      renderers: [
        { id: 'twig', name: 'Twig' },
        { id: 'excel', name: 'Excel' },
        { id: 'plates', name: 'Plates' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'isSupervisior',
      'isAdministrator',
      'documentTemplate'
    ]),
    page () {
      return {
        id: this.documentTemplate && this.documentTemplate.id,
        name: this.documentTemplate && this.documentTemplate.id
          ? this.$t('Document')
          : this.$t('New document')
      }
    },
    headerModel () {
      if (!this.documentTemplate) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-white',
              value: this.documentTemplate.id ? this.$t('Document ID: ') + this.documentTemplate.id : this.$t('New Document')
            }
          ]
        }
      ]
    },
    schema () {
      return {
        isLoading: !this.documentTemplate,
        groups: [
          {
            entity: 'Orderadmin\\Document\\Entity\\Template',
            entityState: this.documentTemplate && this.documentTemplate.state,
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.documentTemplate && this.documentTemplate.name,
                field: 'name',
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                required: true,
                onChange: name => {
                  this.updateDocumentTemplate({ name })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                field: 'type',
                value: this.documentTemplate && this.documentTemplate.type,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.types,
                required: true,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return this.$t(row.name)
                  }
                  return this.$t((this.types.find(x => x.id === row) || { name: row }).name || '')
                },
                onChange: (type) => {
                  this.updateDocumentTemplate({ type: type.id })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Entity Class'),
                field: 'entityClass',
                value: this.documentTemplate && this.documentTemplate.entityClass,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.entityClasses,
                required: true,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return this.$t(row.name)
                  }
                  return this.$t((this.entityClasses.find(x => x.id === row) || { name: row }).name || '')
                },
                onChange: (entityClass) => {
                  this.updateDocumentTemplate({ entityClass: entityClass.id })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Renderer'),
                field: 'renderer',
                value: this.documentTemplate && this.documentTemplate.renderer,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.renderers,
                required: true,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return this.$t(row.name)
                  }
                  return this.$t((this.renderers.find(x => x.id === row) || { name: row }).name || '')
                },
                onChange: (renderer) => {
                  this.updateDocumentTemplate({ renderer: renderer.id })
                  this.hasChange = true
                }
              },
              {
                if: this.documentTemplate && this.documentTemplate.documentType,
                type: 'input',
                inputType: 'text',
                value: this.documentTemplate && this.documentTemplate.template,
                field: 'template',
                label: this.$t('Template'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                required: true,
                onChange: template => {
                  this.updateDocumentTemplate({ template })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.documentTemplate && this.documentTemplate.conditions,
                field: 'conditions',
                label: this.$t('Template conditions'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                required: true,
                onChange: conditions => {
                  this.updateDocumentTemplate({ conditions })
                  this.hasChange = true
                }
              },
              {
                if: this.documentTemplate && this.documentTemplate.documentType,
                type: 'input',
                inputType: 'text',
                value: this.documentTemplate && this.documentTemplate.documentType,
                field: 'documentType',
                label: this.$t('Document type'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                onChange: documentType => {
                  this.updateDocumentTemplate({ documentType })
                  this.hasChange = true
                }
              },
              {
                if: this.isSupervisior || this.isAdministrator,
                type: 'multiselect',
                label: this.$t('Owner'),
                field: 'owner',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.documentTemplate && this.documentTemplate._embedded.owner,
                disabled: !!this.documentTemplate && !!this.documentTemplate.id,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: owner => {
                  this.updateDocumentTemplateEmbedded({ owner })
                  this.hasChange = true
                }
              },
              {
                type: 'switch',
                value: this.documentTemplate && !!this.documentTemplate.isFile,
                field: 'isFile',
                label: this.$t('Is file'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                onChange: isFile => {
                  this.updateDocumentTemplate({ isFile })
                  this.hasChange = true
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.params.id && !this.documentTemplate) {
      this.loadDocumentTemplate(this.$route.params.id)
    } else if (!this.$route.params.id) {
      this.setNewDocumentTemplate()
    }
  },
  unmounted () {
    this.setDocumentTemplate(null)
  },
  methods: {
    ...mapActions([
      'loadDocumentTemplate',
      'saveDocumentTemplate'
    ]),
    ...mapMutations([
      'setDocumentTemplate',
      'setNewDocumentTemplate',
      'updateDocumentTemplate',
      'updateDocumentTemplateEmbedded',
      'upsertDocumentTemplate'
    ]),
    handleUpdate (template) {
      this.updateDocumentTemplate({ template })
      this.hasChange = true
    },
    handleChange () {
      this.hasChange = true
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    save () {
      this.isSaveLoading = true
      this.saveDocumentTemplate()
        .then((item) => {
          this.upsertDocumentTemplate(item)
          this.hasChange = false

          if (!this.$route.params.id) {
            this.handleBack()
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    }
  }
}
</script>
